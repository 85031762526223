import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { RecordsService, BreadcrumbService, QuizService } from '../services';
import { FormBuilder, Validators,FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {
  private id: string;
  records: any = [];
  returnRecords: any = [];
  breadcrumb_data: any = [];
  quizDetail: any;
  quiz: any;
  alertService: any;

  message = {
    status: '',
    msg: ''
  };

  pageSize: number = 10;//number of item per page
  currentPage = 1;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page; // change the page number as the page button is clicked
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnRecords = this.records.slice(startItem, endItem);
  }
  constructor(
    private recordsService: RecordsService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private quizService: QuizService
  ) {
    this.breadcrumb_data.push({name: 'Dashboard', link: '/dashboard'});
  }


  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if (params.quiz) {
        this.id = params['quiz'];
      } else {
        let message = 'Invalid link.'
        this.alertService.error(message, true);
        this.router.navigate([`/records?quiz= id`]);
      }
    });

    this.getQuizDetail(this.id);
    this.getRecords();
  }

  getQuizDetail(id) {
    this.quizService.getEachQuiz(id)
      .pipe(first())
      .subscribe(
        data => {
          this.quiz = data;
          let quizTitle = data.single_attempt ?
            `${data.title} - Real Test - Records` :
            `${data.title} - Practise Test - Records`;
          this.breadcrumb_data.push({name: data.category_name, link: `dashboard/sub-categories?category=${data.category}`});
          this.breadcrumb_data.push({name: data.sub_category_name, link: `dashboard/quizzes?category=${data.category}&sub_category=${data.sub_category}`});
          this.breadcrumb_data.push({name: quizTitle, link: ''});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  getRecords() {
    var params = {
      quiz: this.id
    }
    this.recordsService.getRecords(params).subscribe(result => {
      this.quizDetail = result.records.length > 0 ? result.records[0] : undefined;
      this.records = this.quizDetail ? this.quizDetail.sittings : [];
      if(this.records.length > 0) {
        for (var i = this.records.length - 1; i >= 0; i--) {
          let eachRecord = this.records[i];
          eachRecord.time_difference = eachRecord.time_difference ? eachRecord.time_difference.split(':') : [];
          if(eachRecord.time_difference.length > 1)
            eachRecord.time_difference = `${eachRecord.time_difference[0]} H ${eachRecord.time_difference[1]} M ${eachRecord.time_difference[2]} S`
          eachRecord.result_status = eachRecord.percentage < eachRecord.quiz_pass_marks ? 'Fail' : 'Pass';
        }
      }

      // for pagination
      this.returnRecords =this.records.slice(0,10);
    }, error => {
      let errorMessage = error.detail ?
        error.detail :
        'Unable to process the operation. Please contact the administrator.';
      this.message = {
        status: 'danger',
        msg: errorMessage
      };
    });
  }

  getGraph(imagePath) {
    if (imagePath != null)
    {
      let image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imagePath);
      return image;
    }
  }
}
