import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticateService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticateService: AuthenticateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // if ([401, 403].indexOf(err.status) !== -1) {
      //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //   this.authenticateService.removeSession();
      //   location.reload(true);
      // }

      // const error = err.error.message || err.statusText;
      // return throwError(error);

      /*  my own way */
      const currentUser = this.authenticateService.currentUserValue;
      if (err.status === 401 && currentUser) {
        // auto logout if 401 response returned from api
        this.authenticateService.removeSession();
        location.reload(true);
      }

      const error = err.error || err.statusText;
      return throwError(error);
  }))
  }
}
