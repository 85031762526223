import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import {
  AuthenticateService,
  BreadcrumbService,
  CategoryService,
  QuizService,
  UserService,
  RecordsService,
} from "../../services";
import { User } from "../../_models/user";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-user-record",
  templateUrl: "./user-record.component.html",
  styleUrls: ["./user-record.component.scss"],
})
export class UserRecordComponent implements OnInit {
  currentUser: User;
  users = [];
  userSitting: any;
  records: any = [];
  quizDetail: any;
  message = {
    msg: "",
    status: "",
  };
  userDetailQuiz;
  userDetailForm: FormGroup;
  userDetail: any;
  sittingDetail: any = {};
  collection = [];
  breadcrumb_data: any = [];
  quiz: any;
  showOnePerPage = [];
  takeQuiz = false;
  showInstructions = true;
  userSittingId: any;
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */

  QACurrentPage: number = 1;
  totalQuestionAns: number = 0;
  quizId: string;
  userId: string;
  modalRef: BsModalRef;

  constructor(
    private authenticateService: AuthenticateService,
    private breadcrumbService: BreadcrumbService,
    private userService: UserService,
    private quizService: QuizService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private recordService: RecordsService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.breadcrumb_data.push({ name: "Dashboard", link: "/dashboard" });
  }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get("userId");
    this.quizId = this.route.snapshot.paramMap.get("quizId");
    this.viewUserRecord(this.userId, this.quizId);
    this.getQuizDetail(this.quizId);
  }

  viewUserRecord(userId, quizeId) {
    let params = { quiz: quizeId, user: userId };
    this.recordService.getRecords(params).subscribe(
      (result) => {
        this.userSitting = result.records[0];

        this.quizDetail =
          result.records.length > 0 ? result.records[0] : undefined;
        this.records = this.quizDetail ? this.quizDetail.sittings : [];

        if (this.records.length > 0) {
          for (var i = this.records.length - 1; i >= 0; i--) {
            let eachRecord = this.records[i];
            eachRecord.time_difference = eachRecord.time_difference
              ? eachRecord.time_difference.split(":")
              : [];
            if (eachRecord.time_difference.length > 1)
              eachRecord.time_difference = `${eachRecord.time_difference[0]} H ${eachRecord.time_difference[1]} M ${eachRecord.time_difference[2]} S`;
            eachRecord.result_status =
              eachRecord.percentage < eachRecord.quiz_pass_marks
                ? "Fail"
                : "Pass";
          }
        }
      },
      (error) => {
        let errorMessage = error.detail
          ? error.detail
          : "Unable to process the operation. Please contact the administrator.";
        this.message = {
          status: "danger",
          msg: errorMessage,
        };
      }
    );
  }
  getGraph(imagePath) {
    if (imagePath != null) {
      let image = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + imagePath
      );
      return image;
    }
  }

  //view record

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-xl" });
  }

  getRecordSitting(sittingId) {
    let params = { sitting: sittingId };
    this.recordService.getRecordSitting(params).subscribe(
      (data) => {
        this.sittingDetail = data;

        this.collection = data.response;

        if (this.sittingDetail.duration_time && this.takeQuiz) {
          let splitTimer = data.duration_time.split(":"),
            hour: number = parseInt(splitTimer[0]),
            min: number = parseInt(splitTimer[1]),
            sec: number = parseInt(splitTimer[2]);
          let totalSeconds: number = hour * 3600 + min * 60 + sec;
        }
        //2:05:34 = > [2,05,34]

        for (var i = this.collection.length - 1; i >= 0; i--) {
          let question = this.collection[i];
          question.user_answers =
            question.user_answer && question.user_answer !== "undefined"
              ? JSON.parse(question.user_answer.replace(/"/g, ""))
              : [];

          if (question.user_answers.length > 0 && !data.answer_at_end) {
            question.processed = true;
          }

          /* get the explanation links */
          question.questionUrls = [];
          if (question.URL) {
            question.questionUrls = question.URL.split(",");
          }

          /* check if the question has multiple correct answers and show choose 2 or 3 answers if it has multiple answers */
          let multipleCorrect = this.getCorrectAnswers(question);
          question.multipleCorrectLength = multipleCorrect.length;
        }

        data.time_difference = data.time_difference
          ? data.time_difference.split(":")
          : [];
        if (data.time_difference.length > 1)
          data.time_difference = `${data.time_difference[0]} H ${data.time_difference[1]} M ${data.time_difference[2]} S`;
        data.total_correct_marks =
          data.number_correct * data.points_per_question;
        data.total_marks = data.totalquestions * data.points_per_question;
        data.percentage_obtained = (
          (data.total_correct_marks / data.total_marks) *
          100
        ).toFixed(2);
        data.result_status =
          data.percentage_obtained < data.pass_marks ? "Fail" : "Pass";

        /* get number of quiz attempts */
        let param = {
          quiz: data.quiz,
          user: this.userId,
        };

        this.quizService
          .getSitting(param)
          .pipe(first())
          .subscribe(
            (sitting) => {
              let filterResult = sitting.results.filter((s) => {
                let result = s.complete && s.id <= sittingId;

                return result;
              });

              data.number_of_quiz_attempts = filterResult.length;
            },
            (sitErr) => {}
          );

        this.showOnePerPage = this.collection.slice(0, 1);
      },
      (error) => {}
    );
  }
  getQuizDetail(id) {
    this.quizService
      .getEachQuiz(id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.quiz = data;
          let quizTitle = data.single_attempt
            ? `${data.title} - Real Test - Records`
            : `${data.title} - Practise Test - Records`;
          this.breadcrumb_data.push({
            name: data.category_name,
            link: `dashboard/sub-categories?category=${data.category}`,
          });
          this.breadcrumb_data.push({
            name: data.sub_category_name,
            link: `dashboard/quizzes?category=${data.category}&sub_category=${data.sub_category}`,
          });
          this.breadcrumb_data.push({ name: quizTitle, link: "" });
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        (error) => {
          let errorMessage = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the administrator.";
          this.message = {
            status: "danger",
            msg: errorMessage,
          };
        }
      );
  }

  getAnswerStatus(question, eachAnswer) {
    let result = {
      class: "",
      text: "",
    };

    if (
      question.user_answers.indexOf(eachAnswer.id) > -1 &&
      !eachAnswer.correct
    ) {
      result.class = "text-danger";
      result.text = "incorrect";
    } else if (
      question.user_answers.indexOf(eachAnswer.id) > -1 &&
      eachAnswer.correct
    ) {
      result.class = "text-success";
      result.text = "correct";
    } else if (
      question.user_answers.indexOf(eachAnswer.id) === -1 &&
      eachAnswer.correct
    ) {
      result.class = "text-success";
      result.text = "correct";
    }
    return result;
  }

  getCorrectAnswers(question) {
    let filterResult = question.choices.filter((s) => {
      let result = s.correct;
      return result;
    });
    return filterResult;
  }
}
