import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService, AuthenticateService, BreadcrumbService, CategoryService, QuizService } from '../../services';
import { User } from '../../_models/user';

@Component({
  selector: 'app-quizes',
  templateUrl: './quizes.component.html',
  styleUrls: ['./quizes.component.scss']
})
export class QuizesComponent implements OnInit {
  message = {
    status: '',
    msg: ''
  };
  currentUser: User;
  catId = null;
  subCatId = null;
  breadcrumb_data: any;
  RecordsService: any;
  recordsDetails: any;
  recordsDetail: any;
  recordsService: any;
  totalFreeQuizzes: number = 0;
  totalRealQuizzes: number = 0;
  freeQuizList = [];

  pageSize: number = 10; /* number of items per page */
  freeCurrentPage = 1; /* here the freeCurrentPage of pagination is set as 1. */

  /* on page changed, change the list of array per page */
  freePageChanged(event: any): void {
    this.freeCurrentPage = event.page;
    this.getFreeQuizzes();
  }
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  returnRealquiz = []; // it is the paginated list of the real quiz

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page; // change the page number as the page button is clicked
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnRealquiz = this.realQuizzesAvailable.slice(startItem, endItem);
  }

  constructor(
    private alertService: AlertService,
    private authenticateService: AuthenticateService,
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private quizService: QuizService
  ) {
    this.authenticateService.currentUser.subscribe(x => this.currentUser = x);

    this.breadcrumb_data = [{name: 'Dashboard', link: '/dashboard'}];
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.category && params.sub_category) {
          this.catId = params.category;
          this.subCatId = params.sub_category;
        } else {
          let message = 'Invalid link.'
          this.alertService.error(message, true);
          this.router.navigate([`/sub-categories?category=${this.catId}`]);
        }
      });

    this.getSubCategoryDetail(this.subCatId);
    this.getFreeQuizzes();
    this.getRealQuizzesAvailable();
  }

  subCategoryDetail:any = {};
  getSubCategoryDetail(id) {
    this.categoryService.getEachSubCategory(id)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategoryDetail = data;

          /* update breadcrumb */
          this.breadcrumb_data.push({name: data.category_name, link: `dashboard/sub-categories?category=${this.catId}`});
          this.breadcrumb_data.push({name: data.sub_category, link: ''});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  getFreeQuizzes() {
    let params = {
      page: this.freeCurrentPage,
      single_attempt: false,
      category: this.catId,
      sub_category: this.subCatId
    };
    this.quizService.getQuizes(params)
      .pipe(first())
      .subscribe(
        data => {
          this.totalFreeQuizzes = data.count;
          this.freeQuizList = data.results;
        },
        error => {

        }
      );
  }

  realQuizzesAvailable = [];
  getRealQuizzesAvailable() {
    let params = {
      user: this.currentUser.id,
      category: this.catId,
      sub_category: this.subCatId,
    };
    this.quizService.assignedQuizzesToUser(params)
      .pipe(first())
      .subscribe(
        data => {
          this.realQuizzesAvailable = data;
          this.totalRealQuizzes = data.length;

          for (var i = this.realQuizzesAvailable.length - 1; i >= 0; i--) {
            let eachQuiz = this.realQuizzesAvailable[i],
              param = {
              quiz: eachQuiz.id,
              user: this.currentUser.id
            };

            this.quizService.getSitting(param)
              .pipe(first())
              .subscribe(
                sitting => {
                  let filterResult = sitting.results.filter(s => {
                    let result = !s.complete;
                    return result;
                  });
                  eachQuiz.complete = filterResult.length > 0 ?
                    filterResult[0].complete
                    : true;
                },
                sitErr => {

                }
              );
          }

          // for pagination
          this.returnRealquiz =this.realQuizzesAvailable.slice(0,10);

        },
        error => {

        }
      );
  }

}
