import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private subject = new Subject<any>();
  constructor(private router: Router) {
  // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.subject.next();
      }
    });}

  store(breadcrumb_data: []) {
    this.subject.next(breadcrumb_data);
  }

  getBreadcrumb(): Observable<any> {
    return this.subject.asObservable();
  }
}
