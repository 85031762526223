import { LocationStrategy } from "@angular/common";
import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { first } from "rxjs/operators";

import {
  AuthenticateService,
  BreadcrumbService,
  CategoryService,
  QuestionAnswerService,
  QuizService,
} from "../../services";
import { User } from "../../_models/user";
@Component({
  selector: "app-take-quiz",
  templateUrl: "./take-quiz.component.html",
  styleUrls: ["./take-quiz.component.scss"],
})
export class TakeQuizComponent implements OnInit {
  userSittingId = null;
  quizId = null;
  currentPage: number = 1;
  pageSize: number = 1; // items per page
  takeQuiz = false;
  urlFor;
  showInstructions = true;
  currentUser: User;
  message = {
    status: "",
    msg: "",
  };
  breadcrumb_data: any = [];
  loading = false;
  collection = [];
  sittingDetail: any = {};
  showOnePerPage = [];

  constructor(
    private route: ActivatedRoute,
    private authenticateService: AuthenticateService,
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private questionAnswerService: QuestionAnswerService,
    private quizService: QuizService,
    private router: Router,
    private locationStrategy: LocationStrategy
  ) {
    this.authenticateService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    this.breadcrumb_data.push({ name: "Dashboard", link: "/dashboard" });
    this.preventBackButton();
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    // Do more processing...
    if (this.takeQuiz) {
      event.preventDefault();
      event.returnValue = false;

      // you can also use return false here
    }
  }

  ngOnInit() {
    let url = this.route.snapshot.url;
    this.urlFor = url[url.length - 1].path;
    if (
      url.length === 5 &&
      (this.urlFor === "end" || this.urlFor === "view-record")
    ) {
      this.takeQuiz = false;
      this.showInstructions = false;
      this.userSittingId = this.route.snapshot.paramMap.get("usersitting");
      this.getAllQuestions(this.userSittingId);
    } else if (url.length === 5 && this.urlFor === "start") {
      this.takeQuiz = true;
      this.showInstructions = false;
      this.userSittingId = this.route.snapshot.paramMap.get("usersitting");
      this.getAllQuestions(this.userSittingId);
    } else {
      this.showInstructions = true;
    }
    this.quizId = this.route.snapshot.paramMap.get("id");
    this.getQuizDetail(this.quizId);
  }

  /* on click start quiz */
  startQuiz(quiz) {
    let text = quiz.single_attempt
      ? "start"
      : quiz.complete
      ? "start"
      : "continue";
    let start = confirm(`Are you sure, you want to ${text} this quiz?`);
    if (start) {
      let data = {
        quiz: quiz.id,
      };
      this.quizService
        .startQuiz(data)
        .pipe(first())
        .subscribe(
          (data) => {
            let sittingId = data.id;
            this.router.navigate([
              `/take-quiz/${quiz.id}/sitting/${sittingId}/start`,
            ]);
          },
          (error) => {
            let errorMessage = error.detail
              ? error.detail
              : error.response
              ? error.response
              : "Unable to process the operation. Please contact the administrator.";
            this.message = {
              status: "danger",
              msg: errorMessage,
            };
          }
        );
    }
  }

  quizDetail: any = {};
  getQuizDetail(id) {
    this.quizService
      .getEachQuiz(id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.quizDetail = data;
          let param = {
            quiz: data.id,
            user: this.currentUser.id,
          };

          /* update breadcrumb */
          let quizTitle = this.showInstructions
            ? data.title
            : this.takeQuiz
            ? `${data.title} - Start`
            : this.urlFor === "view-record"
            ? `${data.title} - Records`
            : `${data.title} - End`;
          let quizLink =
            this.urlFor === "view-record" ? `records?quiz=${data.id}` : "";
          this.breadcrumb_data.push({
            name: data.category_name,
            link: `dashboard/sub-categories?category=${data.category}`,
          });
          this.breadcrumb_data.push({
            name: data.sub_category_name,
            link: `dashboard/quizzes?category=${data.category}&sub_category=${data.sub_category}`,
          });
          this.breadcrumb_data.push({ name: quizTitle, link: quizLink });
          if (this.urlFor === "view-record")
            this.breadcrumb_data.push({ name: "Each Record", link: "" });

          this.breadcrumbService.store(this.breadcrumb_data);

          this.quizService
            .getSitting(param)
            .pipe(first())
            .subscribe(
              (sitting) => {
                let filterResult = sitting.results.filter((s) => {
                  let result = !s.complete;
                  return result;
                });
                this.quizDetail.complete =
                  filterResult.length > 0 ? filterResult[0].complete : true;
              },
              (sitErr) => {}
            );
        },
        (error) => {
          let errorMessage = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the administrator.";
          this.message = {
            status: "danger",
            msg: errorMessage,
          };
        }
      );
  }

  goPrevious() {
    this.currentPage = this.currentPage - 1;
    const startItem = (this.currentPage - 1) * this.pageSize;
    const endItem = this.currentPage * this.pageSize;
    this.showOnePerPage = this.collection.slice(startItem, endItem);
  }

  /* start timer */
  interval;
  totalSeconds = 120000;
  startTimer(sitting) {
    var self = this;

    this.interval = setInterval(function () {
      sitting.hour = Math.floor(self.totalSeconds / 3600);
      sitting.hour = sitting.hour < 10 ? "0" + sitting.hour : sitting.hour;
      sitting.min = Math.floor((self.totalSeconds / 60) % 60);
      sitting.min = sitting.min < 10 ? "0" + sitting.min : sitting.min;
      sitting.sec = self.totalSeconds % 60;
      sitting.sec = sitting.sec < 10 ? "0" + sitting.sec : sitting.sec;
      self.totalSeconds--;
      /* if the time is 0 then, endQuiz */
      if (self.totalSeconds <= 0) {
        self.endQuiz(sitting.sitting_id);
      }
    }, 1000);
  }

  paused = false;
  pauseTimer() {
    this.paused = true;
    clearInterval(this.interval);
    delete this.interval;
  }

  resumeTimer() {
    this.paused = false;
    if (!this.interval) this.startTimer(this.sittingDetail);
  }

  getAllQuestions(sittingId) {
    let params = {
      sitting: sittingId,
    };
    this.questionAnswerService
      .getAllQuizQuestions(params)
      .pipe(first())
      .subscribe(
        (data) => {
          this.sittingDetail = data;
          this.collection = data.response;

          if (this.sittingDetail.duration_time && this.takeQuiz) {
            let splitTimer = data.duration_time.split(":"),
              hour: number = parseInt(splitTimer[0]),
              min: number = parseInt(splitTimer[1]),
              sec: number = parseInt(splitTimer[2]);
            let totalSeconds: number = hour * 3600 + min * 60 + sec;
            this.totalSeconds = totalSeconds;
            this.startTimer(data);
          }

          for (var i = this.collection.length - 1; i >= 0; i--) {
            let question = this.collection[i];
            question.user_answers =
              question.user_answer && question.user_answer !== "undefined"
                ? JSON.parse(question.user_answer.replace(/"/g, ""))
                : [];

            if (question.user_answers.length > 0 && !data.answer_at_end) {
              question.processed = true;
            }

            /* get the explanation links */
            question.questionUrls = [];
            if (question.URL) {
              question.questionUrls = question.URL.split(",");
            }

            /* check if the question has multiple correct answers and show choose 2 or 3 answers if it has multiple answers */
            let multipleCorrect = this.getCorrectAnswers(question);
            question.multipleCorrectLength = multipleCorrect.length;
          }

          if (!this.showInstructions && !this.takeQuiz) {
            data.time_difference = data.time_difference
              ? data.time_difference.split(":")
              : [];
            if (data.time_difference.length > 1)
              data.time_difference = `${data.time_difference[0]} H ${data.time_difference[1]} M ${data.time_difference[2]} S`;
            data.total_correct_marks =
              data.number_correct * data.points_per_question;
            data.total_marks = data.totalquestions * data.points_per_question;
            data.percentage_obtained = (
              (data.total_correct_marks / data.total_marks) *
              100
            ).toFixed(2);
            data.result_status =
              data.percentage_obtained < data.pass_marks ? "Fail" : "Pass";

            /* get number of quiz attempts */
            let param = {
              quiz: data.quiz,
              user: this.currentUser.id,
            };

            this.quizService
              .getSitting(param)
              .pipe(first())
              .subscribe(
                (sitting) => {
                  let filterResult = sitting.results.filter((s) => {
                    let result = s.complete && s.id <= this.userSittingId;
                    return result;
                  });
                  data.number_of_quiz_attempts = filterResult.length;
                },
                (sitErr) => {}
              );
          }

          this.showOnePerPage = this.collection.slice(0, 1);
        },
        (error) => {}
      );
  }

  getAnswer(answer, question, event: any) {
    if (question.multiple_answer) {
      let index = question.user_answers.indexOf(answer),
        checked = event.target.checked;
      if (index > -1 && !checked) question.user_answers.splice(index, 1);
      else if (index === -1 && checked) question.user_answers.push(answer);
    } else {
      question.user_answers = [answer];
    }
    question.processed = false;
  }

  /* not in use right now */
  submitAllAnswers(collection) {
    for (var i = 0; i < collection.length; i++) {
      let userAnswers = collection[i].user_answers;
      this.storeAnswer(collection[i], collection);
    }
  }

  storeAnswer(each, collection) {
    if (each.user_answers.length === 0) {
      alert("Please select the answer.");
      return false;
    }

    let correct_answers = this.getCorrectAnswers(each);
    if (each.user_answers.length !== correct_answers.length) {
      alert(
        `Please choose ${correct_answers.length} answers for this question.`
      );
      return false;
    }

    this.loading = true;

    let answerData = {
      sitting: this.sittingDetail.sitting_id,
      question: each.question_id,
      user_guess: JSON.stringify(each.user_answers),
    };

    this.quizService
      .submitAnswer(answerData)
      .pipe(first())
      .subscribe(
        (data) => {
          /* if the page number and the total no. of questions equal, then end the quiz automatically */
          if (this.currentPage === collection.length)
            this.endQuiz(this.sittingDetail.sitting_id);

          /* auto move to next question, after answer is successfully submitted */
          if (this.currentPage < collection.length) {
            this.currentPage = this.currentPage + 1;
            const startItem = (this.currentPage - 1) * this.pageSize;
            const endItem = this.currentPage * this.pageSize;
            this.showOnePerPage = this.collection.slice(startItem, endItem);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          // let errorMessage = error.detail ?
          //   error.detail :
          //   'Unable to process the operation. Please contact the administrator.';
          // this.message = {
          //   status: 'danger',
          //   msg: errorMessage
          // };
        }
      );
  }

  endQuiz(sitting) {
    let data = {
      sitting: sitting,
    };
    this.quizService
      .completeQuiz(data)
      .pipe(first())
      .subscribe(
        (response) => {
          this.loading = false;
          this.router.navigate([
            `/take-quiz/${response.response.quiz}/sitting/${this.userSittingId}/end`,
          ]);
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  checkAnswer(each) {
    if (each.user_answers.length === 0) {
      alert("Please select the answer.");
      return false;
    }

    each.processed = true;
  }

  getAnswerStatus(question, eachAnswer) {
    let result = {
      class: "",
      text: "",
    };

    if (
      question.user_answers.indexOf(eachAnswer.id) > -1 &&
      !eachAnswer.correct
    ) {
      result.class = "text-danger";
      result.text = "incorrect";
    } else if (
      question.user_answers.indexOf(eachAnswer.id) > -1 &&
      eachAnswer.correct
    ) {
      result.class = "text-success";
      result.text = "correct";
    } else if (
      question.user_answers.indexOf(eachAnswer.id) === -1 &&
      eachAnswer.correct
    ) {
      result.class = "text-success";
      result.text = "correct";
    }
    return result;
  }

  getCorrectAnswers(question) {
    let filterResult = question.choices.filter((s) => {
      let result = s.correct;
      return result;
    });
    return filterResult;
  }
}
