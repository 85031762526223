import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; // if ngModule is used then FormModule is required
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, UserService } from '../../services';

@Component({
  selector: 'app-change-existing',
  templateUrl: './change-existing.component.html',
  styleUrls: ['./change-existing.component.scss']
})
export class ChangeExistingComponent implements OnInit {
  changePassForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    /* form validation */
    this.changePassForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, {validators: this.checkPasswords });
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePassForm.controls; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  changePassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.changePassForm.invalid) {
      return;
    }

    this.loading = true;

  const userData = {
      password: this.f.password.value
    };
    this.userService.changeExistingPassword(userData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          const message = 'Password changed successfully.';
          this.alertService.success(message);
          this.changePassForm.reset();
        },
        error => {
          this.loading = false;
          this.alertService.error(error);
        });
  }

}
