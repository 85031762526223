import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { CategoryService } from '../../services';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  totalCategories: number = 0;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getCategoriesAvailable();
  }
  constructor(
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.getCategoriesAvailable();
  }

  categoriesAvailable = [];
  getCategoriesAvailable() {
    let params = {
      page: this.currentPage
    };
    this.categoryService.getCategories(params)
      .pipe(first())
      .subscribe(
        data => {
          this.categoriesAvailable = data.results;
          this.totalCategories = data.count;
        },
        error => {

        }
      );
  }

}
