import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; // if ngModule is used then FormModule is required
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticateService, UserService } from '../../services';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  resetPassForm: FormGroup;
  verificationCode = '';
  submitted = false;
  loading = false;

  constructor(
    private authenticateService: AuthenticateService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    // redirect to dashboard if already logged in
    if (this.authenticateService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.code) {
          this.userService.verifyUserEmail(params)
            .pipe(first())
            .subscribe(
              data => {
                this.verificationCode = params.code;
                this.alertService.success('Please reset your password.');
              },
              error => {
                this.alertService.error('Invalid link.', true);
                this.router.navigate(['/']);
              });
        } else {
          let message = 'Invalid link.'
          this.alertService.error(message, true);
          this.router.navigate(['/']);
        }
      });

    /* form validation */
    this.resetPassForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, {validators: this.checkPasswords })
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPassForm.controls; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  resetPassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    }

    this.loading = true;

    let userData = {
      code: this.verificationCode,
      password: this.f.password.value
    };
    this.userService.resetPassword(userData)
      .pipe(first())
      .subscribe(
        data => {
          let message = 'Password reset successfully. Please login.';
          this.alertService.success(message, true);
          this.router.navigate(['/login']);
        },
        error => {
          this.loading = false;
          this.alertService.error(error);
        });
  }
}
