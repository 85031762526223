import { Component, OnInit, Input } from '@angular/core';
import { Event, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';

import { BreadcrumbService } from '../services';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb_data: any;
  private subscription: Subscription;
  currentPage: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {
    this.subscription = this.breadcrumbService.getBreadcrumb().subscribe(breadcrumb => {
      this.breadcrumb_data = breadcrumb;
    });
  }
  // @Input()
  // set breadcumb(breadcumb_data: any){
  //   this.breadcumb_data = breadcumb_data;
  // }

  // get breadcumb(){
  //   return this.breadcumb_data;
  // }

  ngOnInit() {
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        this.currentPage = route.data.currentPage;
        // do something
      }
    });
  }

}
