import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

declare let $: any;

@Component({
  selector: 'answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent {
    // we will pass in answer from Question component
    @Input('group')

    public answerForm: FormGroup;
    answerLength: number = 0;

    ngOnChanges(changes: SimpleChanges) {
      // to make the checkbox label unique for each answer so that on selecting the label selects the respective checkbox
      this.answerLength = $('.answer-form').length;
    }

}
