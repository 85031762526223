import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; // if ngModule is used then FormModule is required
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticateService, AlertService, UserService } from '../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPassForm: FormGroup;
  submitted = false;
  emailSubmitted = false;
  emailLoading = false;
  loading = false;
  sendEmailMessage = {
    status: '',
    msg: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private authenticateService: AuthenticateService,
    private alertService: AlertService,
    private router: Router,
    private userService: UserService
  ) {
    // redirect to dashboard if already logged in
    if (this.authenticateService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
      // email: new FormControl('', {
      // validators: [Validators.required, Validators.minLength(6)], updateOn: 'blur'} ),
      // password: new FormControl('', {
      // validators: [Validators.required], updateOn: 'blur'} )
    });
    this.forgotPassForm = this.formBuilder.group({
      requestEmail: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get requestEmailControl() { return this.forgotPassForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    this.authenticateService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/dashboard']);
        },
        error => {
          this.loading = false;
          const errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.alertService.error(errorMessage);
        });
  }

  sendEmail() {
    this.emailSubmitted = true;
    // stop here if form is invalid
    if (this.forgotPassForm.invalid) {
      return;
    }
    this.emailLoading = true;

    const data = {
      email: this.requestEmailControl.requestEmail.value
    };
    this.userService.requestToResetPassword(data)
      .pipe(first())
      .subscribe(
        data => {
          this.emailLoading = false;
          this.sendEmailMessage = {
            status: 'success',
            msg: 'Email sent. Please click the link provided and reset your password.'
          };
        },
        error => {
          this.emailLoading = false;
          const errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';

          this.sendEmailMessage = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  resetModal() {
    this.forgotPassForm.reset();
    this.sendEmailMessage = {
      status: '',
      msg: ''
    };
  }

}
