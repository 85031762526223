import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbService, CategoryService } from '../../services';

declare var $: any;
@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  addSubCategoryForm: FormGroup;
  breadcrumb_data: any;
  form = {
    label: 'Add',
    id: null
  };
  loading = false;
  submitted = false;
  message = {
    status: '',
    msg: ''
  };
  catId = null;
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  totalSubcategoryLogs:number;// it is the paginated list of the category

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getSubCategoryList();
  }
  constructor(
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.breadcrumb_data = [{name: "Dashboard", link: '/dashboard'}, {name:"Category List", link: '/dashboard/category'}];
  }

  ngOnInit() {
    this.catId = this.route.snapshot.paramMap.get('id');
    this.getCategoryDetail(this.catId);

    this.getSubCategoryList();

    this.addSubCategoryForm = this.formBuilder.group({
      subCategory: ['', Validators.required],
      sub_category_logo:[null],
      description:['',Validators.required],
    });
  }

  /* get the error or any value */
  get subCategoryControl() {
    return this.addSubCategoryForm.controls;
  }

  categoryDetail:any={};
  getCategoryDetail(id) {
    this.categoryService.getEachCategory(id)
      .pipe(first())
      .subscribe(
        data => {
          this.categoryDetail = data;
          this.breadcrumb_data.push({name: data.category});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  subCategories = [];
  getSubCategoryList() {
    let params = {
      category: this.catId,
      page: this.currentPage
    };

    this.categoryService.getSubCategories(params)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategories = data.results;
          this.totalSubcategoryLogs =data.count;
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.sub_category ?
              error.sub_category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }
  onImageSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.subCategoryControl.sub_category_logo.setValue(file);

      var fileName = file.name;
      $(event.target).next('.custom-file-label').html(fileName);
    }
  }


  /* add sub-category */
  addSubCategory() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addSubCategoryForm.invalid) {
      return;
    }
    this.loading = true;

    const formData = new FormData();
    formData.append('category',this.catId);
    formData.append('sub_category',this.subCategoryControl.subCategory.value),
    formData.append('description',this.subCategoryControl.description.value)
    if (this.subCategoryControl.sub_category_logo.value){
    formData.append('sub_category_logo',this.subCategoryControl.sub_category_logo.value);
    }


    this.categoryService.createSubCategory(formData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          let successMessage = 'Sub category added';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  change(each) {
    event.preventDefault();
    this.form.label = each ? 'Edit' : 'Add';
    this.form.id = each ? each.id : null;
    let subCategory = each ? each.sub_category : '';
    let desc = each ? each.description : '';
    this.subCategoryControl.subCategory.setValue(subCategory);
    this.subCategoryControl.description.setValue(desc);
    this.message = {
      status: '',
      msg: ''
    };
  }

  /* update sub-category */
  updateSubCategory() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addSubCategoryForm.invalid) {
      return;
    }
    this.loading = true;

    const formData = new FormData();
    formData.append('category',this.catId);
    formData.append('sub_category',this.subCategoryControl.subCategory.value),
    formData.append('description',this.subCategoryControl.description.value)
    if (this.subCategoryControl.sub_category_logo.value){
    formData.append('sub_category_logo',this.subCategoryControl.sub_category_logo.value);
    }

    this.categoryService.updateSubCategory(this.form.id, formData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          let successMessage = 'Sub Category updated.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            (error.sub_category ?
              error.sub_category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  /* delete sub-category */
  deleteSubCategory(id) {
    event.preventDefault();
    var isConfirmed = confirm('Are you sure to delete this sub-category?');
    if(!isConfirmed) {
      return false;
    }
    this.categoryService.deleteSubCategory(id)
      .pipe(first())
      .subscribe(
        data => {
          let successMessage = 'Sub Category deleted.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.sub_category ?
              error.sub_category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

}
