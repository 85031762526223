import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { SignupVerifyComponent } from './signup-verify/signup-verify.component';
import { ResetComponent } from './password/reset/reset.component';
import { ChangeExistingComponent } from './password/change-existing/change-existing.component';
import { CategoryComponent } from './admin/category/category.component';
import { SubCategoryComponent } from './admin/sub-category/sub-category.component';
import { QuizComponent } from './admin/quiz/quiz.component';
import { QuestionComponent } from './admin/quiz/question/question.component';
import { SubCategoriesComponent } from './user/sub-categories/sub-categories.component';
import { QuizesComponent } from './user/quizes/quizes.component';
import { TakeQuizComponent } from './user/take-quiz/take-quiz.component';
import { RecordsComponent } from './records/records.component';
import { LandingpageComponent } from './landingpage/landingpage.component';


import { AuthGuard } from './guard/auth.guard';
import { Role } from './_models/role';
import { UserRecordComponent } from './admin/user-record/user-record.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'register-user', component: SignupComponent },
  { path: 'signup/verify', component: SignupVerifyComponent },
  { path: 'password/reset', component: ResetComponent },
  { path: 'settings', component: ChangeExistingComponent, canActivate: [AuthGuard] },
  { path: '', component: LandingpageComponent },

  /* for admin */
  { path: 'dashboard/category', component: CategoryComponent, canActivate: [AuthGuard], data: {roles: Role.Admin} },
  { path: 'dashboard/category/:id', component: SubCategoryComponent, canActivate: [AuthGuard], data: {roles: Role.Admin} },
  { path: 'dashboard/category/:catId/sub_category/:subCatId/quiz', component: QuizComponent, canActivate: [AuthGuard], data: {roles: Role.Admin} },
  {path: 'dashboard/view-record/:userId/:quizId',component: UserRecordComponent, canActivate: [AuthGuard], data: {roles: Role.Admin}},
  { path: 'dashboard/category/:catId/sub_category/:subCatId/quiz/:quizId/question-answer', component: QuestionComponent, canActivate: [AuthGuard], data: {roles: Role.Admin} },
  { path: 'dashboard/category/:catId/sub_category/:subCatId/quiz/:quizId/edit-question-answer/:questionId', component: QuestionComponent, canActivate: [AuthGuard], data: {roles: Role.Admin} },

  /* for normal users */
  {
    path: 'dashboard',
    children: [
      { path: 'sub-categories', component: SubCategoriesComponent, canActivate: [AuthGuard], data: {roles: Role.User} }
    ]
  },
  {
    path: 'dashboard',
    children: [
      { path: 'quizzes', component: QuizesComponent, canActivate: [AuthGuard], data: {roles: Role.User} }
    ]
  },
  { path: 'take-quiz/:id', component: TakeQuizComponent, canActivate: [AuthGuard], data: {roles: Role.User} },
  { path: 'take-quiz/:id/sitting/:usersitting/start', component: TakeQuizComponent, canActivate: [AuthGuard], data: {roles: Role.User, currentPage: 'start_quiz'} },
  { path: 'take-quiz/:id/sitting/:usersitting/end', component: TakeQuizComponent, canActivate: [AuthGuard], data: {roles: Role.User} },
  /* for checking records */
  { path: 'records', component: RecordsComponent, canActivate: [AuthGuard], data: {roles: Role.User }},
  { path: 'quiz/:id/sitting/:usersitting/view-record', component: TakeQuizComponent, canActivate: [AuthGuard], data: {roles:Role.User} },
  // otherwise redirect to dashboard
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
