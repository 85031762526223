import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import {
  AuthenticateService,
  BreadcrumbService,
  CategoryService,
  QuizService,
  UserService,
  RecordsService,
} from "./../services";
import { User } from "./../_models/user";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

declare var $: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  emailSubmitted = false;
  emailLoading = false;
  loading = false;
  sendEmailMessage = {
    status: "",
    msg: "",
  };
  currentUser: User;
  users = [];
  inviteSubmitted = false;
  inviteLoading = false;
  assignedQuizList = [];
  collection = [];
  sittingDetail: any = {};
  showOnePerPage = [];
  userSittingId = null;
  takeQuiz = false;
  showInstructions = true;
  message = {
    msg: "",
    status: "",
  };
  inviteModalMessage = {
    msg: "",
    status: "",
  };
  updateModalMessage = {
    msg: "",
    status: "",
  };
  updateLoading = false;
  removeLoading = false;
  userDetailForm: FormGroup;
  inviteUserForm: FormGroup;
  updateUserForm: FormGroup;
  removeQuizForm: FormGroup;
  userDetail: any;
  userDetailQuiz;
  quizDetail: any;
  records: any = [];
  userSitting: any;
  allQuizList = [];
  modalRef: BsModalRef;

  totalUsers: number = 0;
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */

  QACurrentPage: number = 1;
  totalQuestionAns: number = 0;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getUsers();
  }

  constructor(
    private authenticateService: AuthenticateService,
    private breadcrumbService: BreadcrumbService,
    private userService: UserService,
    private quizService: QuizService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private recordService: RecordsService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.authenticateService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    let breadcrumb: any = [
      {
        name: "Dashboard",
        link: "/dashboard",
      },
    ];
    this.breadcrumbService.store(breadcrumb);
  }

  ngOnInit() {
    this.initInviteForm();
    this.initUpdateForm();
    this.initRemoveForm();
    this.initUserDetailForm();
    this.getUsers();
  }
  initUserDetailForm() {
    this.userDetailForm = this.formBuilder.group({
      first_name: [{ value: "", disabled: true }, Validators.required],
      last_name: [{ value: "", disabled: true }, Validators.required],
      email: [{ value: "", disabled: true }, Validators.required],
    });
  }

  initInviteForm() {
    this.inviteUserForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      quizes: [[]],
      catSelected: false,
      subCatSelected: false,
    });
  }

  get inviteForm() {
    return this.inviteUserForm.controls;
  }

  initUpdateForm() {
    this.updateUserForm = this.formBuilder.group({
      user: [""],
      quizes: [[]],
    });
  }

  get updateForm() {
    return this.updateUserForm.controls;
  }

  initRemoveForm() {
    this.removeQuizForm = this.formBuilder.group({
      user: [""],
      quizes: [[]],
    });
  }

  get removeForm() {
    return this.removeQuizForm.controls;
  }

  getUsers() {
    let params = {
      is_superuser: false,
      page: this.currentPage,
    };
    this.userService
      .getAllUsers(params)
      .pipe(first())
      .subscribe(
        (data) => {
          this.users = data.results;
          this.totalUsers = data.count;
        },
        (error) => {
          const errorMessage = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the administrator.";
          this.message = {
            status: "danger",
            msg: errorMessage,
          };
        }
      );
  }

  getCategories() {
    let dropList = [];
    let getCategories = (pageNumber: number) => {
      var paramUser = {
        page: pageNumber,
      };

      this.categoryService
        .getCategories(paramUser)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.next) {
              pageNumber++;
              getCategories(pageNumber);
            }
            let categoryList = data.results;

            categoryList.forEach((result) => {
              dropList.push(result);
              this.getSubCategories(result);
            });

            if (!data.next) {
              this.allQuizList = dropList;
            }
          },
          (error) => {}
        );
    };
    getCategories(1);
  }
  getSubCategories(category) {
    let dropList = [];
    category.subcategories = [];
    let getSubcategories = (pageNumber: number) => {
      var paramUser = {
        page: pageNumber,
        category: category.id,
      };

      this.categoryService
        .getSubCategories(paramUser)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.next) {
              pageNumber++;
              getSubcategories(pageNumber);
            }
            let subcatList = data.results;

            subcatList.forEach((result) => {
              dropList.push(result);
              this.getQuizzes(result);
            });

            if (!data.next) {
              category.subcategories = dropList;
            }
          },
          (error) => {}
        );
    };
    getSubcategories(1);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-xl" });
  }

  getSelectedUserDetail(id) {
    this.userService
      .getUserWithId(id)

      .subscribe((res) => {
        this.userDetail = res;

        this.userDetailQuiz = res.quizes;

        this.userDetailForm.patchValue(this.userDetail);
      });
  }

  viewRecord(quiz_id) {
    this.modalRef.hide();

    let route = `dashboard/view-record/${this.userDetail.id}/${quiz_id}`;
    this.router.navigate([route]);
  }

  getQuizzes(subCategory) {
    let dropList = [];
    subCategory.quizzes = [];
    let getCategorizedQuizzes = (pageNumber) => {
      let params = {
        page: pageNumber,
        category: subCategory.category,
        sub_category: subCategory.id,
        single_attempt: true,
        draft: false,
      };
      this.quizService
        .getQuizes(params)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data.next) {
              pageNumber++;
              getCategorizedQuizzes(pageNumber);
            }
            let quizzes = data.results;
            quizzes.forEach((result) => {
              if (!result.draft) {
                dropList.push(result);
              }
            });

            if (!data.next) {
              subCategory.quizzes = dropList;
            }
          },
          (error) => {
            const errorMessage = error.detail
              ? error.detail
              : "Unable to process the operation. Please contact the administrator.";
            this.message = {
              status: "danger",
              msg: errorMessage,
            };
          }
        );
    };
    getCategorizedQuizzes(1);
  }

  onInviteUser() {
    this.inviteSubmitted = true;
    //stop if form is invalid
    if (this.inviteUserForm.invalid) {
      return;
    }

    let quizLength = this.inviteForm.quizes.value;
    if (quizLength.length === 0) {
      this.inviteModalMessage.status = "danger";
      this.inviteModalMessage.msg = "Please select at least one quiz.";
      return;
    }
    this.inviteLoading = true;
    let userData = new FormData();
    userData.append("email", this.inviteForm.email.value);
    userData.append("quizes", JSON.stringify(this.inviteForm.quizes.value));

    this.userService
      .inviteUser(userData)
      .pipe(first())
      .subscribe(
        (data) => {
          this.message.status = "success";
          this.message.msg = "Successfully invited user.";
          $("#inviteUserModal").modal("hide");
        },
        (error) => {
          this.updateLoading = false;
          let message = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the admininstrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }

  assignedQuizzes(user) {
    this.userDetail = user;

    let params = {
      user: user.id,
    };
    this.quizService
      .assignedQuizzesToUser(params)
      .pipe(first())
      .subscribe(
        (data) => {
          this.assignedQuizList = data;
        },
        (error) => {
          const errorMessage = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the administrator.";
          this.message = {
            status: "danger",
            msg: errorMessage,
          };
        }
      );
  }

  quizExistsInAssigned(quiz) {
    let filterResult = this.assignedQuizList.filter((eachQuiz) => {
      return eachQuiz.id === quiz.id;
    });

    if (filterResult.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // get each checked or unchecked quizzes and set the value in the form
  triggerChanges(quiz, event) {
    let form = this.updateForm,
      quizzes = form.quizes.value;
    let index = quizzes.indexOf(quiz.id),
      checked = event.target.checked;
    if (index > -1 && !checked) quizzes.splice(index, 1);
    else if (index === -1 && checked) quizzes.push(quiz.id);
  }

  inviteUser(quiz, event) {
    let form = this.inviteForm,
      quizzes = form.quizes.value;
    let index = quizzes.indexOf(quiz.id),
      checked = event.target.checked;
    if (index > -1 && !checked) quizzes.splice(index, 1);
    else if (index === -1 && checked) quizzes.push(quiz.id);
  }

  triggerRemoveChanges(quiz, event) {
    let form = this.removeForm,
      quizzes = form.quizes.value;
    let index = quizzes.indexOf(quiz.id),
      checked = event.target.checked;
    if (index > -1 && !checked) quizzes.splice(index, 1);
    else if (index === -1 && checked) quizzes.push(quiz.id);
  }

  onUpdateUser() {
    //stop if form is invalid
    let quizLength = this.updateForm.quizes.value;
    if (quizLength.length === 0) {
      this.updateModalMessage.status = "danger";
      this.updateModalMessage.msg = "Please select at least one quiz.";
      return;
    }
    this.updateModalMessage.status = "";
    this.updateModalMessage.msg = "";

    this.updateLoading = true;
    let userData = new FormData();
    userData.append("user", this.userDetail.id);
    userData.append("quizes", JSON.stringify(quizLength));

    this.quizService
      .addQuizToUser(userData)
      .pipe(first())
      .subscribe(
        (data) => {
          this.initUpdateForm();
          this.updateLoading = false;
          this.updateModalMessage.status = "success";
          this.updateModalMessage.msg =
            "Successfully assigned quizzes to the user.";
          this.assignedQuizzes(this.userDetail);
        },
        (error) => {
          this.updateLoading = false;
          let message = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the admininstrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }
  deleteUser(id) {
    let makeSure = confirm("Are you sure you want to delete this user?");
    if (!makeSure) return;

    this.userService
      .deleteUser(id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.updateModalMessage.status = "success";
          this.updateModalMessage.msg = "User deleted successfully.";
          // after the user is deleted we call userlist function
          this.getUsers();
        },
        (error) => {
          let message = "";
          if (error.detail) message = error.detail;
          else if (error.failed) message = error.failed;
          else
            message =
              "Unable to process the operation. Please contact the administrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }
  deactivateUser(id) {
    let makeSure = confirm("Are you sure you want to deactivate this user?");
    if (!makeSure) return;
    let userdata = {
      is_active: false,
    };
    this.userService
      .updateUser(id, userdata)
      .pipe(first())
      .subscribe(
        (data) => {
          this.updateLoading = false;
          this.updateModalMessage.status = "success";
          this.updateModalMessage.msg = "User deactivated successfully";
          this.getUsers();
        },
        (error) => {
          let message = "";
          if (error.detail) message = error.detail;
          else if (error.failed) message = error.failed;
          else
            message =
              "Unable to process the operation. Please contact the administrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }
  activateUsers(id) {
    let makeSure = confirm("Are you sure you want to activate this user?");
    if (!makeSure) return;

    let userdata = {
      is_active: true,
    };
    this.userService
      .updateUser(id, userdata)
      .pipe(first())
      .subscribe(
        (data) => {
          this.updateLoading = false;
          this.updateModalMessage.status = "success";
          this.updateModalMessage.msg = "User activated successfully";
          this.getUsers();
        },
        (error) => {
          let message = "";
          if (error.detail) message = error.detail;
          else if (error.failed) message = error.failed;
          else
            message =
              "Unable to process the operation. Please contact the administrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }

  removeQuiz() {
    //stop if form is invalid
    let quizLength = this.removeForm.quizes.value;
    if (quizLength.length === 0) {
      this.updateModalMessage.status = "danger";
      this.updateModalMessage.msg = "Please select at least one quiz.";
      return;
    }
    this.updateModalMessage.status = "";
    this.updateModalMessage.msg = "";

    this.removeLoading = true;
    let userData = new FormData();
    userData.append("user", this.userDetail.id);
    userData.append("quizes", JSON.stringify(this.removeForm.quizes.value));

    this.quizService
      .deleteQuizFromUser(userData)
      .pipe(first())
      .subscribe(
        (data) => {
          this.initRemoveForm();
          this.removeLoading = false;
          this.updateModalMessage.status = "success";
          this.updateModalMessage.msg =
            "Successfully removed quiz access from this user.";
          this.assignedQuizzes(this.userDetail);
        },
        (error) => {
          this.removeLoading = false;
          let message = error.detail
            ? error.detail
            : "Unable to process the operation. Please contact the admininstrator.";
          this.updateModalMessage.status = "danger";
          this.updateModalMessage.msg = message;
        }
      );
  }

  resetInviteModal() {
    this.initInviteForm();
    this.inviteModalMessage = {
      msg: "",
      status: "",
    };
    this.inviteSubmitted = false;
    this.inviteLoading = false;
    this.getCategories();
  }

  resetUpdateModal() {
    this.initUpdateForm();
    this.initRemoveForm();
    this.updateModalMessage = {
      msg: "",
      status: "",
    };
    this.updateLoading = false;
    this.removeLoading = false;
    this.getCategories();
  }
  // assign all the quizzes of the sub-category while inviting user
  assignSubcategoryQuizzesInvite(quizzes, event, catIndex, subcatIndex) {
    let form = this.inviteForm,
      formQuizzes = form.quizes.value;
    let clickedElement = event.target,
      checked = clickedElement.checked;

    let checkboxes = $(clickedElement)
      .closest(".card-body")
      .find(`.quizzes-group${catIndex}${subcatIndex}`)
      .find('input[type="checkbox"]');

    // make boolean true if selected else false
    if (checked) {
      checkboxes.prop("disabled", true).prop("checked", true);
    } else {
      checkboxes.prop("disabled", false).prop("checked", false);
    }

    // first reset the invite form quizzes
    formQuizzes = [];

    // get all the quizzes of the sub-category and assign to the form quizes
    quizzes.forEach((quiz) => {
      let index = formQuizzes.indexOf(quiz.id);

      if (index > -1 && !checked) formQuizzes.splice(index, 1);
      else if (index === -1 && checked) formQuizzes.push(quiz.id);
    });

    form.quizes.setValue(formQuizzes);
  }

  // assign all the quizzes of the category while inviting user
  assignCategoryQuizzesInvite(category, event, catIndex) {
    let form = this.inviteForm,
      formQuizzes = form.quizes.value;
    let clickedElement = event.target,
      checked = clickedElement.checked;

    let checkboxes = $(clickedElement)
      .closest(".card-body")
      .find(`.sub-category-group${catIndex}`)
      .find('input[type="checkbox"]');

    // make boolean true if selected else false
    if (checked) {
      checkboxes.prop("disabled", true).prop("checked", true);
    } else {
      checkboxes.prop("disabled", false).prop("checked", false);
    }

    // first reset the invite form quizzes
    formQuizzes = [];

    let allCatQuizzes = [];

    category.subcategories.forEach((eachSubcategory) => {
      if (eachSubcategory.quizzes.length > 0) {
        let quizzes = eachSubcategory.quizzes;
        quizzes.forEach((eachQuiz) => {
          allCatQuizzes.push(eachQuiz);
        });
      }
    });

    // get all the quizzes of the sub-category and assign to the form quizes
    allCatQuizzes.forEach((quiz) => {
      let index = formQuizzes.indexOf(quiz.id);

      if (index > -1 && !checked) formQuizzes.splice(index, 1);
      else if (index === -1 && checked) formQuizzes.push(quiz.id);
    });

    form.quizes.setValue(formQuizzes);
  }

  // assign all the quizzes of the sub-category to the user
  assignSubcategoryQuizzes(quizzes, event) {
    // first reset the update form
    this.initUpdateForm();
    let form = this.updateForm,
      formQuizzes = form.quizes.value;
    let checked = event.target.checked;

    // get all the quizzes of the sub-category and assign to the form quizes
    quizzes.forEach((quiz) => {
      let index = formQuizzes.indexOf(quiz.id);

      if (index > -1 && !checked) formQuizzes.splice(index, 1);
      else if (index === -1 && checked) formQuizzes.push(quiz.id);
    });

    // now call the update function
    this.onUpdateUser();
  }

  // assign all the quizzes of the category to the user
  assignCategoryQuizzes(category, event) {
    // first reset the update form
    this.initUpdateForm();
    let form = this.updateForm,
      formQuizzes = form.quizes.value;
    let checked = event.target.checked;

    let allCatQuizzes = [];

    category.subcategories.forEach((eachSubcategory) => {
      if (eachSubcategory.quizzes.length > 0) {
        let quizzes = eachSubcategory.quizzes;
        quizzes.forEach((eachQuiz) => {
          allCatQuizzes.push(eachQuiz);
        });
      }
    });

    // get all the quizzes of the sub-category and assign to the form quizes
    allCatQuizzes.forEach((quiz) => {
      if (category.id === quiz.category) {
        let index = formQuizzes.indexOf(quiz.id);

        if (index > -1 && !checked) formQuizzes.splice(index, 1);
        else if (index === -1 && checked) formQuizzes.push(quiz.id);
      }
    });

    // now call the update function
    this.onUpdateUser();
  }

  // remove whole assigned quizzes of category from the user
  removeCatQuizzes(category, event) {
    // first reset the remove form
    this.initRemoveForm();
    let form = this.removeForm,
      formQuizzes = form.quizes.value;
    let checked = event.target.checked;

    let allCatQuizzes = [];

    category.subcategories.forEach((eachSubcategory) => {
      if (eachSubcategory.quizzes.length > 0) {
        let quizzes = eachSubcategory.quizzes;
        quizzes.forEach((eachQuiz) => {
          allCatQuizzes.push(eachQuiz);
        });
      }
    });

    // get all the quizzes of the sub-category and assign to the form quizes
    allCatQuizzes.forEach((quiz) => {
      if (category.id === quiz.category) {
        let index = formQuizzes.indexOf(quiz.id);

        if (index > -1 && !checked) formQuizzes.splice(index, 1);
        else if (index === -1 && checked) formQuizzes.push(quiz.id);
      }
    });
    // now call the update function
    this.removeQuiz();
  }

  // remove all the quizzes of the sub-category to the user
  removeSubcategoryQuizzes(quizzes, event) {
    // first reset the remove form
    this.initRemoveForm();
    let form = this.removeForm,
      formQuizzes = form.quizes.value;
    let checked = event.target.checked;

    // get all the quizzes of the sub-category and assign to the form quizes
    quizzes.forEach((quiz) => {
      let index = formQuizzes.indexOf(quiz.id);

      if (index > -1 && !checked) formQuizzes.splice(index, 1);
      else if (index === -1 && checked) formQuizzes.push(quiz.id);
    });

    // now call the update function
    this.removeQuiz();
  }

  getTotalAssignedQuizzes(category, sub_category) {
    let assignedQuizzes = this.assignedQuizList.filter((eachQuiz) => {
      if (category) {
        return category === eachQuiz.category;
      }
      if (sub_category) {
        return sub_category === eachQuiz.sub_category;
      } else {
        return false;
      }
    });
    return assignedQuizzes.length;
  }

  getTotalCategoryQuizzes(category) {
    let allCatQuizzes = [];

    category.subcategories.forEach((eachSubcategory) => {
      if (eachSubcategory.quizzes.length > 0) {
        let quizzes = eachSubcategory.quizzes;
        quizzes.forEach((eachQuiz) => {
          allCatQuizzes.push(eachQuiz);
        });
      }
    });

    return allCatQuizzes.length;
  }

  //Password reset  for the user

  resetPassword(param) {
    let makeSure = confirm(
      "Are you sure you want to reset this user password?"
    );
    if (!makeSure) return;

    this.userService
      .requestToResetPassword(param)
      .pipe(first())
      .subscribe(
        (data) => {
          this.emailLoading = false;
          this.sendEmailMessage = {
            status: "success",
            msg: "Email sent to user to reset their password.",
          };
          window.alert(this.sendEmailMessage.msg);
        },
        (error) => {
          this.emailLoading = false;
          let message = "";
          if (error.detail) message = error.detail;
          else if (error.failed) message = error.failed;
          else
            message =
              "Unable to process the operation. Please contact the administrator.";

          this.sendEmailMessage = {
            status: "danger",
            msg: error.Message,
          };
          window.alert(message);
        }
      );
  }
}
