import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { BreadcrumbService, CategoryService } from '../../services';

declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  addCategoryForm: FormGroup;
  form = {
    label: 'Add',
    id: null
  };
  loading = false;
  submitted = false;
  message = {
    status: '',
    msg: ''
  };
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  totalCategoryLogs:number=0;// it is the paginated list of the billingLists

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getCategoryList();
  }
  constructor(
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private formBuilder: FormBuilder
  ) {
    let breadcrumb_data:any = [{name: "Dashboard", link: '/dashboard'}, {name:"Category List", link: '/dashboard/category'}];
    breadcrumbService.store(breadcrumb_data);
  }

  ngOnInit() {
    this.getCategoryList();

    this.addCategoryForm = this.formBuilder.group({
      category: ['', Validators.required],
      category_logo: [null],
      description:['',Validators.required]

    });
  }

  /* get the error */
  get categoryControl() {
    return this.addCategoryForm.controls;
  }

  categories = [];
  getCategoryList() {
    let params = {
      page: this.currentPage
    };
    this.categoryService.getCategories(params)
      .pipe(first())
      .subscribe(
        data => {
          this.categories = data.results;
          this.totalCategoryLogs = data.count;
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }
  onSelectImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.categoryControl.category_logo.setValue(file);

      var fileName = file.name;
      $(event.target).next('.custom-file-label').html(fileName);
    }
  }


  /* add category */
  addCategory() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addCategoryForm.invalid) {
      return;
    }
    this.loading = true;

    const formData =  new FormData();
    formData.append('category',this.categoryControl.category.value),
    formData.append('description',this.categoryControl.description.value)
    if (this.categoryControl.category_logo.value){
    formData.append('category_logo',this.categoryControl.category_logo.value)
    }


    this.categoryService.createCategory(formData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          let successMessage = 'Category added';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  change(each) {
    event.preventDefault();
    this.form.label = each ? 'Edit' : 'Add';
    this.form.id = each ? each.id : null;
    let category = each ? each.category : '';
    let desc = each ? each.description : '';
    this.categoryControl.category.setValue(category);
    this.categoryControl.description.setValue(desc);
    this.message = {
      status: '',
      msg: ''
    };
  }

  /* update category */
  updateCategory() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addCategoryForm.invalid) {
      return;
    }
    this.loading = true;

    const formData =  new FormData();
    formData.append('category',this.categoryControl.category.value),
    formData.append('description',this.categoryControl.description.value)
    if (this.categoryControl.category_logo.value){
    formData.append('category_logo',this.categoryControl.category_logo.value)
    }

    this.categoryService.updateCategory(this.form.id, formData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          let successMessage = 'Category updated.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  /* delete category */
  deleteCategory(params) {
    event.preventDefault();
    let isConfirmed = confirm('Are you sure to delete this category?');
    if(!isConfirmed) {
      return false;
    }
    this.categoryService.deleteCategory(params)
      .pipe(first())
      .subscribe(
        data => {
          let successMessage = 'Category deleted.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

}
