import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  createCategory(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'multipart/form-data');
    return this.http.post<any>(`${this.apiUrl}/category/`, data, {headers: headers})
      .pipe(map(response => {
        return response;
      }));
  }

  getCategories(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/category/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getEachCategory(id) {
    return this.http.get<any>(`${this.apiUrl}/category/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateCategory(id, data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'multipart/form-data');
    return this.http.put<any>(`${this.apiUrl}/category/${id}/`, data,{headers: headers})
      .pipe(map(response => {
        return response;
      }));
  }



  deleteCategory(id) {
    return this.http.delete<any>(`${this.apiUrl}/category/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  createSubCategory(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'multipart/form-data');
    return this.http.post<any>(`${this.apiUrl}/subcategory/`, data, {headers: headers} )
      .pipe(map(response => {
        return response;
      }));
  }


  getSubCategories(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/subcategory/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getEachSubCategory(id) {
    return this.http.get<any>(`${this.apiUrl}/subcategory/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateSubCategory(id, data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type' , 'multipart/form-data');
    return this.http.put<any>(`${this.apiUrl}/subcategory/${id}/`, data,{headers: headers})
      .pipe(map(response => {
        return response;
      }));
  }

  deleteSubCategory(id) {
    return this.http.delete<any>(`${this.apiUrl}/subcategory/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }
}
