import { Component } from '@angular/core';
import { Event, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticateService } from './services';
import { User } from './_models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aws-quiz-fe';

  currentUser: User;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];
  currentPage: any;

  constructor(
    private alertService: AlertService,
    private authenticateService: AuthenticateService,
    private router: Router
  ) {
    this.authenticateService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        this.currentPage = route.data.currentPage;
        // do something
      }
    });
  }

  logout() {
    event.preventDefault();
    this.authenticateService.logout()
    .pipe(first())
    .subscribe(
      response => {
        this.router.navigate(['/']);
      }, error => {
        this.alertService.error(error);
      });
  }
}
