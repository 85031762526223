import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient
  ) { }

  createQuiz(data) {
    return this.http.post<any>(`${this.apiUrl}/quiz/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  getQuizes(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/quiz/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getEachQuiz(id) {
    return this.http.get<any>(`${this.apiUrl}/quiz/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateQuiz(id, data) {
    return this.http.put<any>(`${this.apiUrl}/quiz/${id}/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteQuiz(id) {
    return this.http.delete<any>(`${this.apiUrl}/quiz/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  /* Call this endpoint whenever user starts new quiz or wants to continue certain quiz */
  startQuiz(data) {
    return this.http.post<any>(`${this.apiUrl}/usersitting/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  getSitting(params) {
    const urlParams = {
      params: params
    };
    return this.http.get<any>(`${this.apiUrl}/sitting/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  submitAnswer(data) {
    return this.http.post<any>(`${this.apiUrl}/store_answer/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  completeQuiz(data) {
    return this.http.post<any>(`${this.apiUrl}/complete_quiz/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  assignedQuizzesToUser(params) {
    const urlParams = {
      params: params
    };
    return this.http.get<any>(`${this.apiUrl}/addquizuser/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  addQuizToUser(data) {
    return this.http.post<any>(`${this.apiUrl}/addquizuser/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteQuizFromUser(data) {
    let options = {
      headers: new HttpHeaders(),
      body: data
    };
    return this.http.delete<any>(`${this.apiUrl}/addquizuser/`, options)
      .pipe(map(response => {
        return response;
      }));
  }
}
