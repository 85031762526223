import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RecordsService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  // Call the endpoint to show the records of the users

  getRecords(params) {
    const urlParams = {
      params: params,
    };

    return this.http.get<any>(`${this.apiUrl}/records/`, urlParams).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getRecordSitting(params) {
    const urlParams = {
      params: params,
    };

    return this.http.get<any>(`${this.apiUrl}/all_questions/`, urlParams).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
