import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnswerService {
  apiUrl =  environment.apiUrl
  constructor(
    private http: HttpClient
  ) { }

  createQuestion(data) {
    return this.http.post<any>(`${this.apiUrl}/mcquestion/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  getQuestions(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/mcquestion/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getEachQuestion(id) {
    return this.http.get<any>(`${this.apiUrl}/mcquestion/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateQuestion(id, data) {
    return this.http.put<any>(`${this.apiUrl}/mcquestion/${id}/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteQuestion(id) {
    return this.http.delete<any>(`${this.apiUrl}/mcquestion/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  createAnswer(data) {
    return this.http.post<any>(`${this.apiUrl}/mcqanswer/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  getAnswers(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/mcqanswer/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getEachAnswer(id) {
    return this.http.get<any>(`${this.apiUrl}/mcqanswer/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  updateAnswer(id, data) {
    return this.http.put<any>(`${this.apiUrl}/mcqanswer/${id}/`, data)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteAnswer(id) {
    return this.http.delete<any>(`${this.apiUrl}/mcqanswer/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }

  /* get all the questions for a quiz */
  getAllQuizQuestions(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/all_questions/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }
}
